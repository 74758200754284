(function () {

  angular.module('kmi.lms.home')
    .component('generalHome', {
      template: require('ajs/modules/home/general-home-page-component.html').default,
      controller: GeneralHomeCtrl,
      controllerAs: 'vm'
    });

  function GeneralHomeCtrl(globalConfig) {
    var vm = this;
    vm.settings = globalConfig.settings;
  }
})();
