(function() {

  angular.module('kmi.lms.network')
    .factory('communitySummaryService', communitySummaryService);

  /* @ngInject */
  function communitySummaryService($http) {
    return {
      getSummaryForUser: getSummaryForUser
    };

    function getSummaryForUser() {
      return $http.get('/a/user/user_network/users/me/summary/')
        .then(function(response) {
          return response.data;
        });
    }
  }
})();
