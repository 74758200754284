(function () {

  angular.module('kmi.lms.core.login.components', [
    'kmi.lms.core',
    'kmi.lms.services',
    'globalDependencies'
  ])
    .config(moduleConfig)
    .run(moduleRun);

  function moduleConfig($stateProvider) {
    $stateProvider
      // .state('prompt.corporateLogin', {
      //   'url': '/corporate?inplace&email',
      //   'component': 'corporateLogin',
      //   'params': {
      //     'inplace': null,
      //     'email': null
      //   },
      //   'data': {
      //     'label': 'Corporate Sign in',
      //     'availableFor': ['anonymous']
      //   },
      //   onEnter: function ($window, $q, siteSettingService) {
      //     var siteSetting = siteSettingService.getSiteSetting();
      //     if (siteSetting.integrationLoginPath) {
      //       $window.location.href = siteSetting.integrationLoginPath;
      //       return $q.reject();
      //     }
      //   }
      // })
      .state('logout', {
        'url': '/signout',
        'data': {
          'label': 'Logout',
          'availableFor': ['regularUser', 'admin']
        },
        onEnter: function ($q, $window, $transition$, elmsAuthService, siteSettingService, locationHistory, globalConfig, _) {
          $transition$.abort();

          elmsAuthService.logout()
            .then(function (response) {
              const siteSettings = siteSettingService.getSiteSetting();

              locationHistory.clear();

              if (response && response.targetUrl) {
                $window.location.href = response.targetUrl;
              } else {
                // Open login page if user is impersonated with other user to prompt a re-login with his own credentials.
                if (elmsAuthService.isImpersonated()) {
                  var lastVisitApp = _.get(locationHistory, 'lastVisit.appView');

                  $window.location.href = [
                    '/', siteSettings.basePath || _.trim(globalConfig.base, '/'),
                    lastVisitApp === 'admin' ? '/admin/login' : '/login'
                  ].join('');
                } else {
                  if (siteSettings.landingPageUrl) {
                    $window.location.href = siteSettings.landingPageUrl;
                  } else {
                    $window.location.href = siteSettings.basePath ? ['/', siteSettings.basePath, '/'].join('') :
                      globalConfig.base.replace('admin/', '');
                  }
                }
              }
            });

          return $q.reject();
        }
      });
    // .state('prompt.forgotPassword', {
    //   url: '/forgotPassword?email&accountLocked',
    //   component: 'forgotPasswordComponent',
    //   params: {
    //     email: null,
    //     lockTypeId: null,
    //     accountLocked: null,
    //     lockedDescription: null
    //   },
    //   data: {
    //     label: 'Forgot password',
    //     availableFor: ['anonymous', 'regularUser', 'admin']
    //   }
    // })
    // .state('prompt.passwordAssistance', {
    //   url: '/passwordAssistance?token',
    //   component: 'passwordAssistance',
    //   params: {
    //     token: null
    //   },
    //   data: {
    //     label: 'Password Assistance'
    //   },
    //   availableFor: ['anonymous', 'regularUser', 'admin']
    // })
    // .state('prompt.emailVerificationRequired', {
    //   url: '/email-verification-required',
    //   component: 'emailVerificationRequiredAlertDialog',
    //   data: {
    //     label: 'Email verification required'
    //   },
    //   availableFor: ['regularUser', 'admin'],
    //   resolve: {
    //     verificationInfo: function (currentUser) {
    //       let user = currentUser.get();
    //       return user.getVerificationInfo();
    //     },
    //     user: function (currentUser, User) {
    //       return User.get().then(user => {
    //         currentUser.set(user);
    //         return user;
    //       });
    //     }
    //   }
    // });
  }

  function moduleRun(globalConfig) {
    globalConfig.settings.authorizedStates.push({
      name: 'prompt.emailVerificationRequired',
      params: {}
    }, {
      name: 'prompt.userRegistration.course_provider',
      params: {
        stepCourseProvider: true,
        stepAccountCreated: true
      }
    });
    globalConfig.settings.deepLinkingExcludedStates.push('prompt.emailVerificationRequired');
  }
})();
