(function () {

  angular.module('kmi.lms.network', [
    'kmi.lms.core',
    'kmi.lms.components',
    'linkify'
  ])
    .constant('networkConst', {
      SEARCH_REQUEST_ITEM_COUNT: 10
    })
    .constant('networkProfileCompletenessFields', [
      'firstName', 'lastName', 'picture', 'biography', 'subjectAreas'
    ]);
})();
