(function () {

  angular.module('kmi.lms.learningSeries')
    .factory('LearningSeries', learningSeries)
    .run(moduleRun);

  /* @ngInject */
  function learningSeries($resource, uuid) {
    var LearningSeries = $resource('/a/learning_series/:lsId/', {lsId: '@id'},
      {
        get: {
          method: 'GET',
          transformResponse: transformResponse
        },
        update: {method: 'PUT'},
        stat: {
          method: 'GET',
          url: '/a/learning_series/:lsId/stat/',
          params: {lsId: '@id'},
          isArray: false
        },
      }, {
        stripTrailingSlashes: false
      });

    Object.defineProperty(LearningSeries, 'defaults', {
      get: function () {
        return {
          active: true,
          forceSectionCompletion: false,
          showAfterRegistration: false,
          published: false,
          globalId: uuid.v4()
        };
      }
    });

    LearningSeries.newLearningSeries = function (attrs) {
      return new LearningSeries(angular.extend(LearningSeries.defaults, attrs || {}));
    };

    function transformResponse(data, headers, status) {
      if (status === 200) {
        return data ? angular.extend(LearningSeries.defaults, angular.fromJson(data)) : null;
      }

      if (headers('ContentType') === 'application/json') {
        return angular.fromJson(data);
      }

      return data;
    }

    return LearningSeries;
  }

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('learning_series', function (id) {
      var LearningSeries = $injector.get('LearningSeries');

      return LearningSeries.get({lsId: id}).$promise;
    });
  }

})();
