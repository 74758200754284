(function () {

  angular.module('kmi.lms.learningSeries')
    .component('learningSeriesViewComponent', {
      template: require('ajs/modules/learning_series/view/ls.html').default,
      controller: LearningSeriesViewController,
      controllerAs: 'vm',
      bindings: {
        ls: '<'
      }
    });

  /* @ngInject */
  function LearningSeriesViewController(UserLearningSeries, currentUser, backUrlService, _, globalConfig, $state,
    learningSeriesService, metaService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.backUrl = backUrlService;
    vm.isAnonymous = currentUser.get().anonymous;
    vm.isNumber = angular.isNumber;
    vm.signInWording = globalConfig.signInWording;
    vm.state = $state;
    vm.settings = globalConfig.settings;

    vm.register = register;
    vm.withdraw = withdraw;
    vm.isWithdrawable = isWithdrawable;
    vm.showProgressBar = showProgressBar;

    function onInit() {
      vm.userId = currentUser.get().id;
      bindData();
      metaService.title(vm.ls.name);
    }

    function bindData() {
      vm.loading = true;

      UserLearningSeries.get({
        userId: vm.userId,
        lsId: vm.ls.id
      }, function (response) {
        vm.userLearningSeries = response;
        populateRegistrationStatus();
      }, function () {
        vm.userLearningSeries = new UserLearningSeries({
          userId: vm.userId,
          learningSeriesId: vm.ls.id
        });
      })
        .$promise
        .finally(function () {
          vm.loading = false;
        });
    }

    function populateRegistrationStatus() {
      if (vm.isAnonymous) {
        return;
      }

      learningSeriesService.populateLearningSeriesUserProgress(vm.userId, [vm.userLearningSeries]);
    }

    function register() {
      vm.registering = UserLearningSeries.save({
        userId: vm.userId,
        learningSeries: {id: vm.ls.id}
      })
        .$promise
        .then(function (response) {
          vm.userLearningSeries = response;
          populateRegistrationStatus();
        })
        .finally(function () {
          vm.registering = false;
        });
    }

    function withdraw() {
      vm.withdrawing = vm.userLearningSeries.$delete()
        .then(function () {
          vm.userLearningSeries = new UserLearningSeries({
            userId: vm.userId,
            learningSeriesId: vm.ls.id
          });
        })
        .finally(function () {
          vm.withdrawing = false;
        });
    }

    function isWithdrawable() {
      return currentUser.get().id === vm.userLearningSeries.createdBy.id;
    }

    function showProgressBar() {
      if (vm.settings.courseDetails.hideCollectionsProgressBar) {
        return false;
      }
      return vm.lsStat && vm.userLearningSeries.id && !vm.isAnonymous;
    }
  }
})();
