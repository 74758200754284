(function () {

  angular
    .module('kmi.lms.network')
    .component('networkRotator', {
      template: require('ajs/modules/network/network-rotator.html').default,
      controller: NetworkRotatorController,
      controllerAs: 'vm',
      bindings: {
        networkAvailable: '=?',
        trackingName: '<'
      }
    });

  /* @ngInject */
  function NetworkRotatorController($scope, currentUser, networkRotatorService, networkFollowersService,
    securityService, userSettingsService, _) {
    var vm = this;

    vm.currentUser = currentUser.get();
    vm.showNext = showNext;
    vm.showPrevious = showPrevious;
    vm.$onInit = activate;
    vm.activityOrigin = 'user';

    function activate() {
      var params = {
        suggestedUsers: networkFollowersService.getCacheFlag('suggested') ?
          networkFollowersService.getCachedUsersData('suggested') : null
      };

      vm.networkAvailable = securityService.isStateAvailable('main.user_network') &&
        userSettingsService.user_network_enabled && !!_.get(vm.currentUser, 'userNetwork.profile');
      vm.trackingName = vm.trackingName + '_network_rotator';

      // clear cached flag after loading
      networkFollowersService.setCacheFlag(false);

      vm.loading = networkRotatorService.loadData(vm.currentUser, params)
        .then(function () {
          vm.availableViews = networkRotatorService.getAvailableViews();
          vm.activeView = networkRotatorService.getDefaultView();
          vm.views = networkRotatorService.getRoratorViews();

          vm.position = _.findIndex(vm.availableViews, function (item) {
            return item === vm.activeView;
          });

          if (vm.views.suggestedSubscriptions && vm.views.suggestedSubscriptions.items) {
            networkFollowersService.setCachedUsersData('suggested', vm.views.suggestedSubscriptions);

            $scope.$watch('vm.views.suggestedSubscriptions.items', function (newValue, oldValue) {
              if (!_.isEqual(newValue, oldValue) && (!newValue || !newValue.length)) {
                vm.availableViews = _.without(vm.availableViews, 'suggestedSubscriptions');
                vm.views.suggestedSubscriptions = null;
                showNext();
              }
            });
          }
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function showNext() {
      var index = _.findIndex(vm.availableViews, function (item) {
        return item === vm.activeView;
      });

      vm.position = index === vm.availableViews.length - 1 ? 0 : index + 1;
      vm.activeView = vm.availableViews[vm.position];

      networkRotatorService.setActiveView(vm.activeView);
    }

    function showPrevious() {
      var index = _.findIndex(vm.availableViews, function (item) {
        return item === vm.activeView;
      });

      vm.position = index <= 0 ? vm.availableViews.length - 1 : index - 1;
      vm.activeView = vm.availableViews[vm.position];

      networkRotatorService.setActiveView(vm.activeView);
    }
  }
})();
