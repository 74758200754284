(function () {

  angular.module('kmi.lms.core')
    .factory('userPasswordService', userPasswordService);

  function userPasswordService($http, $q, apiUrl, $uibModal) {
    return {
      providePasswordDialog: providePasswordDialog,
      resetPasswordDialog: resetPasswordDialog,
      changePasswordDialog: changePasswordDialog,
      resetPassword: function (email) {
        var defer = $q.defer();

        $http.post(apiUrl('/a/user/security/recover_password/'), {
          email: email
        }).then(function (res) {
          defer.resolve(res.data);
        }, function () {
          defer.reject();
        });

        return defer.promise;
      },
      getTokenResult: function (token) {
        return $http.get(apiUrl('/a/user/security/assistance/' + token + '/')).then(function (res) {
          return res.data;
        });

      },
      changePasswordByToken: function (token, password) {
        return $http.post(apiUrl('/a/user/security/assistance/' + token + '/'), {
          password: password
        }).then(function (res) {
          return res.data;
        });
      }
    };

    function providePasswordDialog(user) {
      return $uibModal.open({
        component: 'providePasswordModalComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          user: function () {
            return user;
          }
        }
      }).result;
    }

    function resetPasswordDialog(user) {
      $uibModal.open({
        component: 'resetPasswordModalComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          user: function () {
            return user;
          }
        }
      });
    }

    function changePasswordDialog(user) {
      $uibModal.open({
        component: 'changePasswordModalComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          user: function () {
            return user;
          }
        }
      });
    }
  }
})();
