(function () {

  angular.module('kmi.lms.learningSeries')
    .component('sectionRequirementType', {
      template: require('ajs/modules/learning_series/components/section-requirement-type.html').default,
      controller: SectionRequirementTypeController,
      controllerAs: 'vm',
      bindings: {
        section: '<',
      }
    });

  /*@ngInject*/
  function SectionRequirementTypeController(learningSeriesService) {
    var vm = this;

    vm.courseRequirementTypes = learningSeriesService.courseRequirementTypes;
  }
})();
