(function () {

  angular.module('kmi.lms.learningSeries')
    .service('learningSeriesService', learningSeriesService);

  /* @ngInject */
  function learningSeriesService($http, $window, apiUrl, _) {
    var courseRequirementTypes = {
      NCourses: 2,
      allCourses: 3,
      checkedCourses: 4
    };

    return {
      getLearningSeriesForUser: function (lsId) {
        return $http.get(apiUrl('/a/learning_series/' + lsId + '/'));
      },
      downloadProofOfCompletion: downloadProofOfCompletion,
      populateLearningSeriesUserProgress: populateLearningSeriesUserProgress,
      courseRequirementTypes: courseRequirementTypes
    };

    function downloadProofOfCompletion(lsId, userId) {
      $window.open(['/a/learning_series/', lsId, '/users/', userId, '/certificate-content/'].join(''));
    }

    function populateLearningSeriesUserProgress(userId, learningSeries) {
      return $http.put(['/a/user/', userId, '/learning_series/progress/'].join(''), {
        learning_series_ids: _.map(learningSeries, 'learningSeriesId')
      })
        .then(function (response) {
          var statuses = _.keyBy(response.data, 'learning_series_id');
          learningSeries.forEach(function (item) {
            item.statusId = statuses[item.learningSeriesId] ? statuses[item.learningSeriesId].status_id : 'None';
          });
        });
    }
  }
})();
