(function () {

  angular.module('kmi.lms.passwords')
    .directive('validPassword', validPassword);

  /* @ngInject */
  function validPassword($q, passwordPoliciesService, rootScopeService, _) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        ctrl.$asyncValidators.validPassword = function (modelValue, viewValue) {
          if (!viewValue) {
            return $q.when(true);
          }

          let user = scope.$eval(attr.user);

          rootScopeService.broadcast('event:passwordValidationProgress', true);

          return passwordPoliciesService.preValidatePasswordRules(viewValue, user)
            .then(function (rules) {
              let validatedRules = rules;
              rootScopeService.broadcast('event:passwordRulesStatusUpdated', validatedRules);

              return passwordPoliciesService.checkPasswordRules(viewValue, user)
                .then(function (rules) {
                  validatedRules.failedRules = _.union(validatedRules.failedRules, rules.failedRules);
                  validatedRules.completedRules = _.union(validatedRules.completedRules, rules.completedRules);

                  rootScopeService.broadcast('event:passwordRulesStatusUpdated', validatedRules);
                  rootScopeService.broadcast('event:passwordValidationProgress', false);

                  if (validatedRules.failedRules.length) {
                    return $q.reject();
                  }
                  return $q.resolve();
                });
            });
        };
      }
    };
  }
})();
