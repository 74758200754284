(function(){

  angular.module('kmi.lms.integration')
    .factory('vimeoVideoService', vimeoVideoService);

  /* @ngInject */
  function vimeoVideoService(_) {
    return {
      buildVideoEmbedPath: buildVideoEmbedPath
    };

    function buildVideoEmbedPath (videoId, options){
      options = processOptions(options);

      return ['//player.vimeo.com/video/',videoId,'?portrait=0&color=333&', options].join('');
    }

    function processOptions(options){
      var optionsList = [];

      if(!options){
        return '';
      }

      _.forEach(options, function(value, key) {
        optionsList.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      });

      return optionsList.join('&');
    }
  }

})();
