(function () {

  angular.module('kmi.lms.network')
    .factory('networkService', networkService);

  /* @ngInject */
  function networkService(
    networkConst, serverErrorHandlerService, scrollingService, UserNetworkActivity,
    UserNetworkComment, $window, $uibModal, _
  ) {

    return function (networkSearchServiceInstance, vm, $scope) {

      var activity = vm.activity || vm.post;

      return {
        loadData: loadData,
        loadMore: loadMore,
        showHandledServerError: showHandledServerError,
        attachContinuousScroll: attachContinuousScroll,
        search: search,
        loadMoreComments: loadMoreComments,
        toggleLike: toggleLike,
        onDeleteComment: onDeleteComment,
        showEditThreadDialog: showEditThreadDialog,
        getPage: getPage
      };

      //////////

      function loadData() {
        return networkSearchServiceInstance.query()
          .then(function (data) {
            vm.itemCount = data.count;
            vm.activities = data.items;
          })
          .catch(showHandledServerError);
      }

      function attachContinuousScroll(desktopOnly) {
        // Utilize endless scrolling
        scrollingService.attachScrollListener();
        // Listen for the scroll event in order to load additional records
        $scope.$on('content.when-scrolled', function () {
          if (desktopOnly && angular.element($window).width() < 768) {
            return;
          }

          // Cancel if search request is already in progress.
          if (vm.searchRequestPromise || !(vm.activities && vm.activities.length)) {
            return;
          }
          loadMore();
        });
      }

      /**
       * @ngdoc function
       * @name kmi.lms.network.networkService#loadMore
       * @methodOf kmi.lms.network.service:networkService
       * @description
       * Loads additional portion of data
       */
      function loadMore() {
        vm.searchRequestPromise = networkSearchServiceInstance.next()
          .then(function (data) {
            //Append new items to the existing list
            for (var i = 0; i < data.items.length; i++) {
              vm.activities.push(data.items[i]);
            }
          })
          .catch(showHandledServerError)
          .finally(function () {
            vm.searchRequestPromise = null;
          });

        // Tells to UI progress indicator what kind of indication needed
        vm.searchRequestPromise.loadMore = true;
      }

      function getPage() {
        const offset = (vm.currentPage - 1) * vm.itemsPerPage || 0;
        networkSearchServiceInstance.conditions.take = vm.itemsPerPage;
        vm.searchRequestPromise = networkSearchServiceInstance.searchOffset(offset)
          .then(function (data) {
            //Append new items to the existing list
            if (vm.currentPage === 1 && vm.addedActivity && !_.find(data.items, item=>{return parseInt(item.id)===vm.addedActivity.id;})){
              // Search can not process added item. for this we add current logic
              data.items.unshift(vm.addedActivity);
              data.items = data.items.slice(0, vm.itemsPerPage);
              vm.addedActivity = null;
            }
            vm.activities = data.items;
          })
          .catch(showHandledServerError)
          .finally(function () {
            vm.searchRequestPromise = null;
          });

        // Tells to UI progress indicator what kind of indication needed
        vm.searchRequestPromise.loadMore = true;
        return vm.searchRequestPromise;
      }

      function showHandledServerError(reason) {
        return serverErrorHandlerService.handleForbiddenError(reason);
      }

      /**
       * @ngdoc function
       * @name kmi.lms.network.networkService#search
       * @methodOf kmi.lms.network.service:networkService
       * @description
       * Loads initial portion of data from the WS
       */
      function search() {
        var promise = loadData()
          .finally(function () {
            vm.searchRequestPromise = null;
          });

        vm.searchRequestPromise = promise;

        return promise;
      }

      /**
       * @description
       * Loads additional portion of the activity comments
       */
      function loadMoreComments() {
        // skip the event if all data are loaded
        if (activity.comments.count <= activity.comments.items.length) {
          return;
        }

        vm.loadCommentsPromise = UserNetworkActivity
          .getComments({
            activityId: activity.id,
            activityOrigin: vm.activityOrigin,
            offset: activity.comments.items.length,
            take: networkConst.SEARCH_REQUEST_ITEM_COUNT
          }).$promise
          .then(extendCommentsList)
          .catch(showHandledServerError)
          .finally(function () {
            vm.loadCommentsPromise = null;
          });

        function extendCommentsList(data) {
          //Append new items to the existing list
          for (var i = data.items.length - 1; i >= 0; i--) {
            activity.comments.items.unshift(angular.extend(data.items[i], {activityOrigin: vm.activityOrigin}));
          }
        }
      }

      /**
       * @description
       * Like/Unlike comments or activities
       */
      function toggleLike(entity) {
        var originalCount = entity.likes.count;

        entity.likes.count += entity.likes.liked ? -1 : 1;

        vm.likePromise = !entity.likes.liked ? entity.$like() : entity.$unlike();

        vm.likePromise
          .then(function () {
            entity.likes.liked = !entity.likes.liked;
          })
          .catch(function (reason) {
            entity.likes.liked = reason.data.liked;
            entity.likes.count = originalCount;

            if (reason.data.error) {
              reason.data = reason.data.error;
              showHandledServerError(reason);
            }
          })
          .finally(function () {
            vm.likePromise = null;
          });
      }

      /**
       * @description
       * Removes comment from the activity comments collection
       * @param {Object} event
       * @param {UserNetworkComment} comment
       */
      function onDeleteComment(event, comment) {
        activity.comments.items = _.without(activity.comments.items, comment);
        activity.comments.count--;
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      }

      function showEditThreadDialog(thread) {
        vm.addedActivity = null;
        var modalInstance = $uibModal.open({
          component: 'editThreadModalComponent',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            thread: function () {
              return thread;
            }
          }
        });

        return modalInstance.result.then(function (result) {
          var savedActivity = UserNetworkActivity.init(angular.extend(result, {activityOrigin: vm.activityOrigin}));
          if (!thread.id && savedActivity) {
            // Add post to the top of activities collection
            if (!vm.currentPage) {
              vm.activities.unshift(savedActivity);
            }
            vm.itemCount++;
            vm.searchConditions.offset++; // Increment offset in order to get properly data on additional data loading.
          }

          if (vm.currentPage) {
            vm.addedActivity = savedActivity;
            vm.currentPage = 1;
            getPage();
          }

          return savedActivity;
        });
      }
    };
  }
})();
