(function () {

  angular.module('kmi.lms.network')
    .factory('networkSearchService', networkSearchService);

  /* @ngInject */
  function networkSearchService($q, UserNetworkActivity, networkConst){

    return function(searchParams, filter){
      var params = {
          query: '',
          filter: {
            type: filter || 'recent'
          }
        },
        hasMoreItems = true;

      for(var i in searchParams){
        if(searchParams[i]){
          params[i] = searchParams[i];
        }
      }

      return {
        query: function () {
          return performSearch(0);
        },
        searchOffset: (offset)=>{return performSearch(offset);},
        conditions: params,
        next: searchNext
      };

      function performSearch (offset){
        params.offset = offset;

        if (!params.take) {
          params.take = networkConst.SEARCH_REQUEST_ITEM_COUNT;
        }
        return UserNetworkActivity.query(params, function(response){
          // There are no any items if returned items count is less than requested
          hasMoreItems = response.items.length === params.take;

          return response;
        }).$promise;
      }

      function searchNext () {
        if(hasMoreItems){
          return performSearch((params.offset || 0) + networkConst.SEARCH_REQUEST_ITEM_COUNT);
        }
        else{
          var def = $q.defer();
          def.resolve({items: []});
          return def.promise;
        }
      }
    };
  }
})();
