(function () {

  angular.module('kmi.lms.passwords')
    .factory('passwordPoliciesService', passwordPoliciesService);

  function passwordPoliciesService($q, $http, _) {
    let checkPasswordRulesCanceler;

    return {
      getCustomerRules: getCustomerRules,
      checkPasswordRules: checkPasswordRules,
      preValidatePasswordRules: preValidatePasswordRules
    };

    function getCustomerRules(cache) {
      return $http.get('/a/password-rules/customer/', {
        ignoreLoadingBar: true,
        cache: cache
      })
        .then(function (response) {
          let customerRules = response.data;
          return _.filter(customerRules, function (passwordRule) {
            return passwordRule.rule.active && passwordRule.rule.visible;
          });
        });
    }

    function preValidatePasswordRules(password, user) {
      let validationResult = {
        failedRules: [],
        completedRules: []
      };

      return getCustomerRules(true)
        .then(function (rules) {
          rules.forEach(function (passwordRule) {
            if (passwordRule.rule.coversNewPasswords && passwordRule.rule.active && passwordRule.rule.visible) {
              // Regex
              if (_.includes(['EnglishSymbolsPasswordRule', 'LowercaseSymbolsPasswordRule',
                'NonAlphanumericSymbolsPasswordRule', 'NumberSymbolsPasswordRule',
                'UppercaseSymbolsPasswordRule'], passwordRule.rule.internalName)) {
                if (!password.match(passwordRule.rule.ruleData)) {
                  validationResult.failedRules.push(passwordRule.rule.internalName);
                } else {
                  validationResult.completedRules.push(passwordRule.rule.internalName);
                }
              }

              // Min length
              if (passwordRule.rule.internalName === 'MinLengthPasswordsRule' && passwordRule.ruleData) {
                if (password.length < parseInt(passwordRule.ruleData)) {
                  validationResult.failedRules.push(passwordRule.rule.internalName);
                } else {
                  validationResult.completedRules.push(passwordRule.rule.internalName);
                }
              }

              // not include any portion of the users email or username
              if (passwordRule.rule.internalName === 'ForbidContainUserInfoPasswordRule') {
                let fields = [user.loginName, user.firstName, user.lastName, user.email],
                  error = false,
                  passwordToCheck = password.toLowerCase();
                fields.forEach(function (field) {
                  if (!error && field) {
                    field = field.toLowerCase();
                    if (field.indexOf(passwordToCheck) > -1 || passwordToCheck.indexOf(field) > -1) {
                      error = true;
                    }
                  }
                });

                if (error) {
                  validationResult.failedRules.push(passwordRule.rule.internalName);
                } else {
                  validationResult.completedRules.push(passwordRule.rule.internalName);
                }
              }
            }
          });

          return validationResult;
        }, function () {
          return $q.resolve(validationResult);
        });
    }

    function checkPasswordRules(password, user) {
      if (checkPasswordRulesCanceler) {
        checkPasswordRulesCanceler.resolve();
      }
      checkPasswordRulesCanceler = $q.defer();

      let url = '/a/user/password/?action=check_rules';

      if (user.id) {
        url = ['/a/user/', user.id, '/password/?action=check_rules'].join('');
      }

      return $http.put(url, {
        'password': password,
        'oldPassword': user.oldPassword,
        'newUser': !user.id
      }, {
        timeout: checkPasswordRulesCanceler.promise,
        ignoreLoadingBar: true
      })
        .then(function (response) {
          return response.data;
        });
    }
  }
})();
