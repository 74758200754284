(function(){

  angular.module('kmi.lms.network')
    .factory('UserNetworkComment', UserNetworkCommentService);

  /* @ngInject */
  function UserNetworkCommentService($resource, currentUser){
    let UserNetworkComment = $resource('/a/:activityOrigin/user_network/comments/:commentId/',
      {commentId: '@id', activityOrigin: '@activityOrigin'},
      {
        get: {transformResponse: transformResponse},
        update: {method: 'PUT', params: {commentId: '@id', activityOrigin: '@activityOrigin'},
          transformResponse: transformResponse},
        save: {method: 'POST', transformResponse: transformResponse},
        like: {
          method: 'POST',
          params: {commentId: '@id', activityOrigin: '@activityOrigin'},
          isArray: false,
          url: '/a/:activityOrigin/user_network/comments/:commentId/likes/'
        },
        unlike: {
          method: 'DELETE',
          params: {commentId: '@id', activityOrigin: '@activityOrigin'},
          isArray: false,
          url: '/a/:activityOrigin/user_network/comments/:commentId/likes/'
        }
      },
      {stripTrailingSlashes: false}
    );

    function transformResponse(data, headers, status) {
      if (status === 200 || status === 201) {
        let comment = angular.fromJson(data);
        comment.date = new Date(comment.date);
        comment.updated = new Date(comment.updated);
        return comment;
      }
      if (headers('Content-Type') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }

    /**
     * @description
     * Returns a new instance of comment with default data
     * @param attrs
     * @returns {*}
     */
    UserNetworkComment.newComment = function(attrs){
      var user = currentUser.get();
      const currentDateTime = new Date();
      attrs.date = new Date(attrs.date);
      attrs.updated = new Date(attrs.updated);

      return new UserNetworkComment(angular.extend({
        date: currentDateTime,
        updated: currentDateTime,
        text: '',
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        },
        likes: {count:0, liked:false},
        activityOrigin: 'user'
      }, attrs));
    };

    /**
     * @description
     * Returns a new instance of comment with init data
     * @param data
     * @returns {*}
     */
    UserNetworkComment.init = function(data){
      data.date = new Date(data.date);
      data.updated = new Date(data.updated);
      return new UserNetworkComment(data);
    };

    return UserNetworkComment;
  }

})();

